'use strict';

define("WeatherControlStateContainer", [
    "ControlStateContainer",
    "IconLib"
], function (
    ControlStateContainer,
    {
        default: Icons
    }
) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var WeatherField = {
        Timestamp: "timestamp",
        WeatherType: "weatherType",
        Temp: "temperature",
        MinTemp: "minTemperature",
        PerceivedTemp: "perceivedTemperature",
        RelativeHumidity: "relativeHumidity",
        WindSpeed: "windSpeed",
        WindDirection: "windDirection",
        Precipitation: "precipitation",
        BarometricPressure: "barometricPressure"
    };
    return class WeatherControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
            SandboxComponent.registerForStateChangesForUUID(GLOBAL_UUID, this, this.onSunStateChange);
        }

        destroy() {
            clearTimeout(this._dayNightTimeout); // otherwise, the timer might hit when the MS is no longer active.

            super.destroy(...arguments);
        }

        prepareStates(newVals) {
            this.states.forecasts = {
                today: [],
                tomorrow: [],
                dayAfterTomorrow: []
            };
            var actual = newVals[this.control.states.actual];
            var forecast = newVals[this.control.states.forecast];
            var entries = forecast.entries;
            this.states.lastUpdate = actual.lastUpdate; // Bg-178: There may be the possibility that actual is missing the entries property

            this.states.hasValidData = entries && actual.entries && actual.entries.length > 0; // group forecasts by day

            if (this.states.hasValidData) {
                var actualValues = this._sanitizeForecast(actual.entries[0], true);

                this.states.forecasts = this._groupForecastsByDay(entries, actualValues.timestamp);
                this.states.forecasts.today[0] = actualValues;
                this.states.forecasts = this._addDayInfosToForecasts(this.states.forecasts);
                this.states.today = this._getMinMaxTemp(this.states.forecasts.today);
                this.states.tomorrow = this._getMinMaxTemp(this.states.forecasts.tomorrow);
                this.states.dayAfterTomorrow = this._getMinMaxTemp(this.states.forecasts.dayAfterTomorrow);
                this.states.currentTemperature = this.states.forecasts.today[0].temperature;
                this.states.currentIcon = this.states.forecasts.today[0].weatherIcon;
            } // Store the current arguments for an artificial "receivedStates" later when day changes to night.


            this._values = newVals;
        }

        getStateText() {
            if (this.states.hasValidData) {
                return lxFormat("%i°", this.states.currentTemperature); //return lxFormat("%i°", this.states.today.min) + " - " + lxFormat("%i°", this.states.today.max);
            } else {
                return NOT_AVAILABLE;
            }
        }

        getStateTextShort() {
            if (this.states.hasValidData) {
                return this.states.forecasts.today[0].weatherText; //return lxFormat("%.1f°", this.states.currentTemperature);
            } else {
                return NOT_AVAILABLE;
            }
        }

        getStateIcon() {
            var icon = Icon.DEFAULT;

            if (this.states.hasValidData) {
                return this.states.forecasts.today[0].weatherIcon;
            }

            return icon;
        }

        /*getStateIconSmall: function getStateIconSmall() {
                        if (this.states.hasValidData) {
                            var temp = Math.round(this.states.currentTemperature);
                            return { iconSrc: generateValueSvg(temp) };
                        }
                    },*/
        getStateColor() {
            if (this.states.hasValidData) {
                return window.Styles.colors.blue;
            }
        }

        onSunStateChange(sunStates) {
            this.states.sunrise = sunStates.sunrise;
            this.states.sunset = sunStates.sunset;

            this._restartDayNightTimeout();
        }

        _groupForecastsByDay(entries, startTimestamp) {
            var groups = {
                today: [],
                tomorrow: [],
                dayAfterTomorrow: []
            };

            var currGroup,
                thresholds = this._getDayThresholds(startTimestamp),
                thresholdIdx = 1,
                // idx 0 = now = minimum timestamp
                threshold = thresholds[thresholdIdx];

            var summary = null;
            currGroup = groups[threshold.dayKey];

            for (var i = 0; i < entries.length; i++) {
                var entry = entries[i];

                if (entry.timestamp <= thresholds[0].timestamp) {
                    continue;
                }

                if (entry.timestamp > threshold.timestamp) {
                    // add summary of the day processed until now
                    groups[threshold.dayKey].unshift(this._evaluateDaySummary(summary, currGroup.length));
                    summary = null; // update group to next day

                    threshold = thresholds[++thresholdIdx];

                    if (!threshold) {
                        // next forecasts exceed dayAfterTomorrow -> just drop those entries
                        break;
                    }

                    currGroup = groups[threshold.dayKey];
                }

                summary = this._updateDaySummaryObject(summary, entry);
                currGroup.push(this._sanitizeForecast(entry));
            }

            return groups;
        }

        _updateDaySummaryObject(sumObj, dayValues) {
            // simply clone the first value
            if (!sumObj) {
                sumObj = $.extend(true, {}, dayValues);
                sumObj.minTemperature = dayValues.temperature; // using the minValue to identifiy the dayoverview is errorprone as 0 means false - and 0 can be a min temperature.

                sumObj.isDayOverview = true;
            } else {
                for (var key in sumObj) {
                    if (sumObj.hasOwnProperty(key)) {
                        if (key === WeatherField.MinTemp) {
                            if (dayValues[WeatherField.Temp] < sumObj[key]) {
                                sumObj[key] = dayValues[WeatherField.Temp];
                            }
                        } else if (key === WeatherField.Temp || key === WeatherField.WeatherType) {
                            // update max temperature and use wors case weather
                            if (dayValues[key] > sumObj[key]) {
                                sumObj[key] = dayValues[key];
                            }
                        } else if (key === WeatherField.Timestamp || key === "isDayOverview") {// do nothing with the timestamp or the isDayOverview-Flag
                        } else {
                            sumObj[key] += dayValues[key]; // simply create sum of all other entries, at the end the avg will be computed
                        }
                    }
                }
            }

            return sumObj;
        }

        _evaluateDaySummary(sumObj, count) {
            for (var key in sumObj) {
                if (sumObj.hasOwnProperty(key)) {
                    if (key === WeatherField.Timestamp) {
                        sumObj[key] += 43200; // add a half day in seconds so the displayed icon will always be a day icon
                    } else if (key !== WeatherField.MinTemp && // min & max temperature is updated with every new value
                        key !== WeatherField.Temp && key !== WeatherField.WeatherType && // It is nonsense to device the WeatherType and the BOOL isDayOverview
                        key !== "isDayOverview") {
                        sumObj[key] = sumObj[key] / count; // simply create sum of all other entries, at the end the avg will be computed
                    }
                }
            }

            if (sumObj) {
                return this._sanitizeForecast(sumObj);
            } else {
                return {};
            }
        }

        _getDayThresholds(actualTime) {
            var loxoneStartTime = new LxDate(0, true); // TODO-goelza check if hour is really 0 after creating

            var endOfDay = new LxDate(actualTime).endOf('day');
            var todayThreshold = endOfDay.diff(loxoneStartTime, 'seconds');
            var tomorrowThreshold = endOfDay.add(1, 'day').diff(loxoneStartTime, 'seconds'); // add(n, 'day') modifies endOfDay

            var dayAfterTomorrowThreshold = endOfDay.add(1, 'day').diff(loxoneStartTime, 'seconds');
            return [{
                dayKey: 'now',
                timestamp: actualTime.diff(loxoneStartTime, 'seconds')
            }, {
                dayKey: 'today',
                timestamp: todayThreshold
            }, {
                dayKey: 'tomorrow',
                timestamp: tomorrowThreshold
            }, {
                dayKey: 'dayAfterTomorrow',
                timestamp: dayAfterTomorrowThreshold
            }];
        }

        _tsToDate(timestamp) {
            return new LxDate(timestamp, true);
        }

        _sanitizeForecast(forecast, isActual) {
            forecast.timestamp = this._tsToDate(forecast.timestamp);
            forecast.weatherText = this.control.details.weatherTypeTexts[forecast.weatherType];
            forecast.weatherIcon = this._getWeatherIconUrl(forecast.weatherType, forecast.timestamp, isActual);
            forecast.reactWeatherIcon = this._getReactWeatherIcon(forecast.weatherType, forecast.timestamp, isActual)

            switch (forecast.solarRadiation) {
                case 0:
                    forecast.solarRadiation = "0 - 20";
                    break;

                case 1:
                    forecast.solarRadiation = "20 - 40";
                    break;

                case 2:
                    forecast.solarRadiation = "40 - 60";
                    break;

                case 3:
                default:
                    forecast.solarRadiation = "60 - 100";
                    break;
            }

            return forecast;
        }

        /**
         * Add a few important properties to every forecast entry, this makes further use much easier
         * @param forecasts
         * @returns {*}
         * @private
         */
        _addDayInfosToForecasts(forecasts) {
            var forecastKeys = Object.keys(forecasts),
                minTemp = forecasts.today[0].temperature,
                maxTemp = minTemp; // Get the min and max temperature

            forecastKeys.forEach(function (key, forecastKeyIdx) {
                var forecast = forecasts[key];
                forecast.forEach(function (entry) {
                    minTemp = Math.min(minTemp, entry.temperature);
                    maxTemp = Math.max(maxTemp, entry.temperature);
                });
            });
            forecastKeys.forEach(function (key, forecastKeyIdx) {
                var forecast = forecasts[key],
                    forecastEntry = forecast[0],
                    nextForecastEntry = forecast[1],
                    entryInTemp = forecastEntry.temperature,
                    entryOutTemp = null,
                    entryTitle = null,
                    isCurrentDay = forecastKeyIdx === 0; // This prevents the graph from having a little crack
                // We basically st the entryInTemp to the entryOutTemp of the last entry of the previous day

                if (!isCurrentDay) {
                    var preDay = forecasts[forecastKeys[forecastKeyIdx - 1]],
                        preDayEntryKeys = Object.keys(preDay),
                        lastPreDayEntry = preDay[preDayEntryKeys[preDayEntryKeys.length - 1]];
                    entryInTemp = lastPreDayEntry.temperature;
                }

                forecast.forEach(function (forecastEntry, forecastEntryIdx) {
                    nextForecastEntry = forecast[forecastEntryIdx + 1];

                    if (entryOutTemp !== null) {
                        entryInTemp = entryOutTemp;
                    }

                    if (nextForecastEntry) {
                        entryOutTemp = (forecastEntry.temperature + nextForecastEntry.temperature) / 2;
                    } else {
                        entryOutTemp = forecastEntry.temperature;
                    }

                    if (forecastEntry.isDayOverview) {
                        entryTitle = forecastEntry.timestamp.format("dddd"); // Gives the Weekday like "Monday"

                        entryInTemp = null;
                        entryOutTemp = nextForecastEntry !== null && nextForecastEntry.temperature;
                    } else {
                        var timeFormat = LxDate.getTimeFormat(true);

                        if (isUSDateOrder()) {
                            timeFormat = "h a"; // Gives "5 pm"
                        }

                        entryTitle = isCurrentDay && forecastEntryIdx === 0 ? _('mobiscroll.now') : forecastEntry.timestamp.format(timeFormat);
                    }

                    if (!forecastEntry.isDayOverview) {
                        forecastEntry.inTemp = entryInTemp;
                        forecastEntry.outTemp = entryOutTemp;
                        forecastEntry.forecastMinTemp = minTemp;
                        forecastEntry.forecastMaxTemp = maxTemp;
                    }

                    forecastEntry.title = entryTitle;
                }.bind(this));
            }.bind(this));
            return forecasts;
        }

        _getWeatherIconUrl(weatherTypeId, time, isCurrentWeather) {
            var isNight = this._getIsNight(time, isCurrentWeather, this.states),
                daySuffix = weatherTypeId <= 4 ? "_" + (isNight ? 'n' : 'd') : '',
                imgName = 'weather_' + lxFormat("%02d", weatherTypeId - 1) + daySuffix + '.svg';

            return "resources/Images/ActiveMiniserver/Weather/" + imgName;
        }

        _getReactWeatherIcon(weatherTypeId, time, isCurrentWeather) {
            // Attention, there is a bug in some LoxBerry installation that leads to a weatherType = 0
            // which is invalid so we don't have an icon for that.
            // Do check if we got an icon for the weatherType!!!
            let isNight = this._getIsNight(time, isCurrentWeather, this.states),
                daySuffix = weatherTypeId <= 4 ? (isNight ? 'n' : 'd') : '',
                imgName = `Weather${lxFormat("%02d", weatherTypeId - 1)}${daySuffix}`;

            return Icons[imgName];
        }

        _getMinMaxTemp(values) {
            var min = values[0].temperature,
                max = values[0].temperature;
            var i = 1;

            while (i < values.length) {
                if (values[i].temperature < min) {
                    min = values[i].temperature;
                } else if (values[i].temperature > max) {
                    max = values[i].temperature;
                }

                i++;}


            return {
                min: min,
                max: max
            };
        }

        /**
         * Evaluates whether a day or a night icon is to be used based on the sunrise and sunset values
         * @param time
         * @param isCurrentWeather  if true, the day and night check is precise, otherwise it ignores the times minute attributes.
         * @param [states]          if known, pass in the current sunstates as object (states.sunrise & states.sunset)
         * @return {boolean}
         * @private
         */
        _getIsNight(time, isCurrentWeather, states) {
            var isNight = false,
                sunstates = states || SandboxComponent.getStatesForUUID(GLOBAL_UUID).states,
                min = time.minutes(),
                hrs = time.hours(),
                rise = sunstates ? sunstates.sunrise : null,
                rHrs = rise ? rise.hour() : 6,
                rmin = rise ? rise.minutes() : 0,
                set = sunstates ? sunstates.sunset : null,
                sMin = set ? set.minutes() : 0,
                sHrs = set ? set.hours() : 21; // determine if it's night or not.

            if (hrs < rHrs || hrs > sHrs) {
                isNight = true;
            } else if (hrs === rHrs) {
                // it's the hour of the sunrise, also use the minutes
                if (isCurrentWeather) {
                    // When it comes to determining day and night for the current time, be specific
                    isNight = min < rmin;
                } else {
                    // For forecasts, the sun has to rise in the first halve of the hour to account as "day"
                    isNight = rmin > 30; // sunrise at 6:18 = day, sunrise at 6:34 = night
                }
            } else if (hrs === sHrs) {
                // it's the hour of the sunrise, also use the minutes
                if (isCurrentWeather) {
                    // When it comes to determining day and night for the current time, be specific
                    isNight = min > sMin;
                } else {
                    // for forecasts, the sun has to set in the first halve of the hour to account as "night".
                    isNight = sMin < 30; // sunset at 19:18 = night, sunrise at 19:34 = day
                }
            }

            return isNight;
        }

        /**
         * Will (re-)start a timeout that will fire each time the miniserver changes from day to night. Required as
         * the current weather icon should change from day to night and vice versa as the time the sun goes down / rises.
         * @private
         */
        _restartDayNightTimeout() {
            var current = SandboxComponent.getMiniserverTimeInfo(),
                date = new Date(current.year(), current.month(), current.date()),
                sunrise = moment(date),
                sunset = moment(date),
                target,
                diff; // a timer may be running already, never run more than one.

            this._dayNightTimeout && clearTimeout(this._dayNightTimeout);
            sunrise.hour(this.states.sunrise.hour());
            sunrise.minutes(this.states.sunrise.minutes());
            sunset.hour(this.states.sunset.hour());
            sunset.minutes(this.states.sunset.minutes()); // what will happen next, sunrise or sunset?

            if (current.isBefore(sunrise)) {
                // sunrise today will be next
                target = sunrise;
            } else if (current.isBefore(sunset)) {
                // Sunset will be next
                target = sunset;
            } else {
                // sunset is passed already - sunrise will be next!;
                sunrise.add(1, "days"); // add a day as the sunrise will be tomorrow.

                target = sunrise;
            } // how many minutes until the next event happens?


            diff = target.diff(current, "minutes") + 1; // ensure the sunrise or -set time has been passed!

            diff = Math.min(diff, 60 * 24); // safety if sth goes wrong, the next sunrise or sunset shouldn't be more than 24 hrs away
            // start a timer to push for another update at the next day/night shift.

            this._dayNightTimeout = setTimeout(function () {
                // trigger state update (the current weather icon needs to change from day to night & vice versa.
                this._values && this.newStatesReceived(this._values); // ensure the time is started.

                this._restartDayNightTimeout();
            }.bind(this), diff * 60 * 1000);
        }

    };
});
