'use strict';

define("IRoomControllerV2021ControlStateContainer",
    [
        "IRoomControllerV2ControlStateContainer",
        'IRoomControllerV2021ControlEnums',
        'IRoomControllerV2ControlEnums',
        './IrcFixedSetpointUtil'
    ], function (
        IRoomControllerV2ControlStateContainer,
        CtrlEnums,
        OldCtrlEnums,
        { Util: FixedSetpointUtil }
    ) {
    return class IRoomControllerV2021ControlStateContainer extends IRoomControllerV2ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            super.prepareStates(...arguments);
            this.states.comfortTempMin = newVals[this.control.states.comfortTemperature];
            this.states.comfortTempMax = newVals[this.control.states.comfortTemperatureCool];
            this.states.ecoTempMax = this.states.comfortTempMax + this.states.absentMaxOffset;
            this.states.ecoTempMin = this.states.comfortTempMin - this.states.absentMinOffset;
            this.states.activeModeText = this._getActiveModeText();
            this.states.activeOpModeText = this._getActiveOpModeText();

            if (FixedSetpointUtil.isFixedSetpointValue(this.states.activeMode)) {
                this.states.fixedSetpointInfo = FixedSetpointUtil.extractFromValue(this.states.activeMode);
            } else {
                this.states.fixedSetpointInfo = false;
            }

            if (this.control.singleComfortTemp) {
                this.states.comfortTolerance = newVals[this.control.states.comfortTolerance];
            } else {
                this.states.comfortTolerance = 0;
            }

            var currMinMax = this._getCurrentMinMaxTemp();

            this.states.currMinTemp = currMinMax.min;
            this.states.currMaxTemp = currMinMax.max;
            this.states.isHeating = this._getIsHeating();
            this.states.isCooling = this._getIsCooling();
            this.states.capabilities = newVals[this.control.states.capabilities];
            this.states.canHeat = hasBit(this.states.capabilities, CtrlEnums.Capability.HEATING);
            this.states.canCool = hasBit(this.states.capabilities, CtrlEnums.Capability.COOLING);
            this.states.hasShades = hasBit(this.states.capabilities, CtrlEnums.Capability.SHADING);
            this.states.shouldCool = this.states.tempActual > this.states.tempTarget;
            this.states.shouldHeat = this.states.tempActual < this.states.tempTarget;
            this.states.isFloating = this.states.currMinTemp < this.states.tempActual < this.states.currMaxTemp;
            this.states.isHeatingNotAllowed = this._getIsHeatingNotAllowed();
            this.states.isCoolingNotAllowed = this._getIsCoolingNotAllowed();
            this.states.isManual = this.states.operatingMode >= OldCtrlEnums.OperatingMode.MANUAL.HEATING_AND_COOLING;
            this.states.shadingTempCool = newVals[this.control.states.shadingCoolTemp];
            this.states.shadingTempHeat = newVals[this.control.states.shadingHeatTemp];
            this.states.shadingActive = !!newVals[this.control.states.shadingOut];

            if (this.states.hasCurrentOverrideEntry) {
                this.states.overrideDescription = this._getOverrideDescription();
                this.states.isTimerActive = this.states.overrideEntries[0].isTimer;
            }

            this.states.tempZone = this._getTempZone();
            this.states.inCoolingZone = this.states.tempZone === CtrlEnums.TempZone.COOLING;
            this.states.inHeatingZone = this.states.tempZone === CtrlEnums.TempZone.HEATING;
            this.states.inFloatingZone = this.states.tempZone === CtrlEnums.TempZone.FLOATING;
            this.states.isHeatingUp = this.states.hasCurrentOverrideEntry && this.states.overrideEntries[0].reason === OldCtrlEnums.Reason.PREPARE_STATE_HEAT_UP;
            this.states.isCoolingDown = this.states.hasCurrentOverrideEntry && this.states.overrideEntries[0].reason === OldCtrlEnums.Reason.PREPARE_STATE_COOL_DOWN;

            this._detectModeIcon();
        }

        getStateTextShort() {
            return this._getActiveModeText();
        }

        _getActiveModeText() {
            var text = "";

            if (FixedSetpointUtil.isFixedSetpointValue(this.states.activeMode)) {
                return _("controls.ircv2021.mode-fixed-setpoint");
            }
            switch (this.states.activeMode) {
                case OldCtrlEnums.Mode.COMFORT:
                    text = _("controls.ircv2021.mode-comfort");
                    break;

                case OldCtrlEnums.Mode.ECO:
                    text = _("controls.ircv2021.mode-eco");
                    break;

                case OldCtrlEnums.Mode.ECO_PLUS:
                    text = _("controls.ircv2021.mode-off");
                    break;

                case OldCtrlEnums.Mode.MANUAL:
                    text = _("controls.ircv2021.mode-fixed-setpoint");
                    break;

                default:
                    break;
            }

            return text;
        }

        _getActiveOpModeText() {
            var text = ""; // since BG-I13093 --> only show auto or fixed setpoint, don't show heating/cooling only

            switch (this.states.operatingMode) {
                case OldCtrlEnums.OperatingMode.AUTOMATIC.HEATING_AND_COOLING:
                case OldCtrlEnums.OperatingMode.AUTOMATIC.ONLY_HEATING:
                case OldCtrlEnums.OperatingMode.AUTOMATIC.ONLY_COOLING:
                    //text = _("controls.ircv2021.mode-auto-explicit");
                    text = _("controls.ircv2021.setpoint-by-schedule");
                    break;

                case OldCtrlEnums.OperatingMode.MANUAL.HEATING_AND_COOLING:
                case OldCtrlEnums.OperatingMode.MANUAL.ONLY_HEATING:
                case OldCtrlEnums.OperatingMode.MANUAL.ONLY_COOLING:
                    text = _("controls.ircv2021.mode-fixed-setpoint");
                    break;

                default:
                    break;
            }

            return text;
        }

        _getCurrentMinMaxTemp() {
            var obj = {
                min: 0,
                max: 0
            };

            if (FixedSetpointUtil.isFixedSetpointValue(this.states.activeMode)) {
                var fixedSetpointInfo = FixedSetpointUtil.extractFromValue(this.states.activeMode)
                return {
                    min: fixedSetpointInfo.target,
                    max: fixedSetpointInfo.target
                }
            }

            switch (this.states.activeMode) {
                case OldCtrlEnums.Mode.COMFORT:
                    obj.min = this.states.comfortTempMin;
                    obj.max = this.states.comfortTempMax;
                    break;

                case OldCtrlEnums.Mode.ECO:
                    obj.min = this.states.ecoTempMin;
                    obj.max = this.states.ecoTempMax;
                    break;

                case OldCtrlEnums.Mode.ECO_PLUS:
                    obj.min = this.states.frostProtectTemperature;
                    obj.max = this.states.heatProtectTemperature;
                    break;

                case OldCtrlEnums.Mode.MANUAL:
                    obj.min = this.states.tempTarget;
                    obj.max = this.states.tempTarget;
                    break;

                default:
                    break;
            }

            return obj;
        }

        _getIsHeatingNotAllowed() {
            var isForbidden = false;

            switch (this.states.operatingMode) {
                case OldCtrlEnums.OperatingMode.AUTOMATIC.ONLY_COOLING:
                case OldCtrlEnums.OperatingMode.MANUAL.ONLY_COOLING:
                    isForbidden = true;
                    break;

                default:
                    isForbidden = false;
                    break;
            }

            return isForbidden;
        }

        _getIsCoolingNotAllowed() {
            var isForbidden = false;

            switch (this.states.operatingMode) {
                case OldCtrlEnums.OperatingMode.AUTOMATIC.ONLY_HEATING:
                case OldCtrlEnums.OperatingMode.MANUAL.ONLY_HEATING:
                    isForbidden = true;
                    break;

                default:
                    isForbidden = false;
                    break;
            }

            return isForbidden;
        }

        _detectModeIcon() {
            this.states.showNoIcon = false;
            this.states.showIconTimer = false;
            this.states.showIconCalendar = false;
            this.states.showIconOpenWindow = false;
            this.states.showIconPresence = false;
            this.states.showIconHeatUp = false;
            this.states.showIconCoolDown = false;

            if (this.states.overrideEntries.length === 0 && this.states.isManual) {
                this.states.showNoIcon = true;
            } else if (this.states.overrideEntries.length === 0) {
                this.states.showIconCalendar = true;
            } else if (this.states.overrideEntries[0].isTimer) {
                this.states.showIconTimer = true;
            } else {
                switch (this.states.overrideEntries[0].reason) {
                    case OldCtrlEnums.Reason.PRESENCE:
                    case OldCtrlEnums.Reason.COMFORT_OVERRIDE:
                        this.states.showIconPresence = true;
                        break;

                    case OldCtrlEnums.Reason.WINDOW_OPEN:
                        this.states.showIconOpenWindow = true;
                        break;

                    case OldCtrlEnums.Reason.ECO_OVERRIDE:
                        this.states.showIconCalendar = true;
                        break;

                    case OldCtrlEnums.Reason.ECO_PLUS_OVERRIDE:
                        this.states.showIconCalendar = true;
                        break;

                    case OldCtrlEnums.Reason.PREPARE_STATE_HEAT_UP:
                        this.states.showIconHeatUp = true;
                        break;

                    case OldCtrlEnums.Reason.PREPARE_STATE_COOL_DOWN:
                        this.states.showIconCoolDown = true;
                        break;

                    default:
                        this.states.showIconCalendar = true;
                        break;
                }
            }
        }

        _getIsHeating() {
            var isHeating = false; // contrary to the operatingMode, the currentMode will always be in onlyHeating or onlyCooling

            switch (this.states.currentMode) {
                case OldCtrlEnums.OperatingMode.AUTOMATIC.ONLY_HEATING:
                case OldCtrlEnums.OperatingMode.MANUAL.ONLY_HEATING:
                    isHeating = true;
                    break;

                default:
                    break;
            }

            return isHeating;
        }

        _getIsCooling() {
            var isCooling = false; // contrary to the operatingMode, the currentMode will always be in onlyHeating or onlyCooling

            switch (this.states.currentMode) {
                case OldCtrlEnums.OperatingMode.AUTOMATIC.ONLY_COOLING:
                case OldCtrlEnums.OperatingMode.MANUAL.ONLY_COOLING:
                    isCooling = true;
                    break;

                default:
                    break;
            }

            return isCooling;
        }

        _getTempZone() {
            var zone = CtrlEnums.TempZone.FLOATING,
                heatingRestricted = !this.states.canHeat || this.states.isHeatingNotAllowed,
                coolingRestricted = !this.states.canCool || this.states.isCoolingNotAllowed; // there is no floating zone when heating or cooling are restricted.

            if (heatingRestricted || coolingRestricted) {
                if (this.states.tempActual > this.states.tempTarget) {
                    zone = CtrlEnums.TempZone.COOLING;
                } else {
                    zone = CtrlEnums.TempZone.HEATING;
                }
            } else if (this.states.tempActual >= this.states.currMaxTemp) {
                zone = CtrlEnums.TempZone.COOLING;
            } else if (this.states.tempActual <= this.states.currMinTemp) {
                zone = CtrlEnums.TempZone.HEATING;
            }

            return zone;
        }

        _getOverrideDescription() {
            var overrideEntry = this.states.overrideEntries[0];
            var modeName;
            var endDate = overrideEntry.stopDate;

            if (FixedSetpointUtil.isFixedSetpointValue(this.states.activeMode)) {
                modeName = this.control._getFixedSetpointName(this.states.activeMode);
            } else {
                switch (this.states.activeMode) {
                    case OldCtrlEnums.Mode.MANUAL:
                        modeName = lxFormat("%.1f°", this.states.tempTarget);
                        break;

                    default:
                        modeName = this.states.activeModeText;
                        break;
                }
            }


            return _('controls.ircv2.mode.active.until', {
                mode: modeName,
                time: LxDate.formatFutureDateDynamic(endDate, true)
            });
        }

    };
});
